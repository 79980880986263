import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Divider, Menu, MenuItem
} from '@mui/material';
import React, {
  useEffect, useState
} from 'react';
import {
  FormProvider, SubmitHandler, useForm
} from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  AppAssetPaths, AppRoutes, canUseAuthComponent, StateStatus
} from '../../app/app.types';
import TextInput from '../../components/forms/TextInput';
import * as yup from 'yup';
import './NewUser.scss';
import DropdownSelect from '../../components/forms/DropdownSelect';
import {
  organizationTypeList, userTypeDropdownList
} from '../../util/dropdown';
import {
  useAppDispatch, useAppSelector
} from '../../app/hooks';
import { userSelectors } from '../../features/user/user.slice';
import {
  createNewUser,
  getEducationLevels,
  getEthnicities,
  getGenders
} from '../../features/user/user.thunks';
import {
  getAllSchoolDistricts, getOrganizationsByType
} from '../../features/organization/organization.api';
import {
  UserApprovalStatus, UserRole
} from '../../features/user/user.model';
import SubmitButton from '../../components/forms/SubmitButton';
import { ListMenuItem } from '../../components/forms/form.types';
import {
  Organization, OrganizationType
} from '../../features/organization/organization.model';
import {
  bottomCenterAnchorOrigin, defaultMenuPaperProps, topCenterTransformOrigin
} from '../../constants/menu.types';
import {
  formatPhone,
  numberOnlyValidation
} from '../../util/formatting';
import {
  snakeCase, startCase
} from 'lodash';
import { setAlert } from '../../features/alert/alert.slice';


interface NewUserProps { }

const NewUser: React.FC<NewUserProps> = () => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const user = useAppSelector(userSelectors.selectUser);
  const genders = useAppSelector(userSelectors.selectGenders);
  const gendersLoaded = useAppSelector(userSelectors.selectGendersLoaded);
  const ethnicities = useAppSelector(userSelectors.selectEthnicities);
  const ethnicitiesLoaded = useAppSelector(userSelectors.selectEthnicitiesLoaded);
  const educationLevels = useAppSelector(userSelectors.selectEducationLevels);
  const educationLevelsLoaded = useAppSelector(userSelectors.selectEducationLevelsLoaded);
  const createNewUserStatus = useAppSelector(userSelectors.selectCreateNewUserStatus);

  const [
    pageLoaded,
    setPageLoaded,
  ] = useState(false);
  const [
    allSchoolsList,
    setAllSchoolsList,
  ] = useState<Organization[]>([]);
  const [
    communityList,
    setCommunityList,
  ] = useState<Organization[]>([]);
  const [
    higherEdOrgList,
    setHigherEdOrgList,
  ] = useState<Organization[]>([]);
  const [
    gradYearList,
    setGradYearList,
  ] = useState<ListMenuItem[]>([]);
  const [
    approvalStatus,
    setApprovalStatus,
  ] = useState<UserApprovalStatus>(UserApprovalStatus.approved);
  const [
    userType,
    setUserType,
  ] = useState<UserRole>(UserRole.student);
  const [
    filteredUserTypeList,
    setFilteredUserTypeList,
  ] = useState<ListMenuItem[]>([]);
  const [
    schoolDistrictList,
    setSchoolDistrictList,
  ] = useState<Organization[]>([]);
  const [
    orgType,
    setOrgType,
  ] = useState<string | number>('');
  const [
    anchorElement,
    setAnchorElement,
  ] = useState<null | HTMLElement>(null);


  useEffect(() => {
    if (!gendersLoaded) {
      dispatch(getGenders());
    }
    if (!ethnicitiesLoaded) {
      dispatch(getEthnicities());
    }
    if (!educationLevelsLoaded) {
      dispatch(getEducationLevels());
    }

    getSchoolsForUser();
    createGradYears();
    handleUserAuth();
    handleUserTypeList();
  }, []);

  useEffect(() => {
    if (
      !pageLoaded &&
      userType &&
      (genders && !!genders.length) &&
      (ethnicities && !!ethnicities.length) &&
      (educationLevels && !!educationLevels.length) &&
      (allSchoolsList && !!allSchoolsList.length)
    ) {
      prePopulateValues();
      setPageLoaded(true);
    }
  }, [
    userType,
    genders,
    ethnicities,
    educationLevels,
    allSchoolsList,
  ]);

  useEffect(() => {
    if (userType == UserRole.districtAdmin && !schoolDistrictList.length) {
      getAllSchoolDistricts().then(res => setSchoolDistrictList(res));
    }
    if (userType === UserRole.community && !communityList.length) {
      getOrganizationsByType(UserRole.community).then(res => setCommunityList(res));
    }
    if (userType === UserRole.community) {
      setOrgType(OrganizationType.Community);
    }
    if (userType == UserRole.institutionAdmin && !higherEdOrgList.length) {
      getOrganizationsByType(OrganizationType.UniversityCollege).then(res => setHigherEdOrgList(res));
    }
  }, [
    userType,
  ]);

  const prePopulateValues = () => {
    if (user.isDistrictInstitutionAdmin) {
      getOrganizationsByType(OrganizationType.UniversityCollege, undefined, true).then(res => setAllSchoolsList(res));
    }
    else if (!user.isAdmin) {
      getOrganizationsByType(user.organization.type, user.organizationId, true).then(res => setAllSchoolsList(res));
    }

    setValue('userType', userTypeDropdownList.find((e) => snakeCase(e.label) === userType));
    setValue('orgType', handleOrgTypeDefaultValue());
    setValue('organization', handleSchoolFieldDefaultValue());
    setValue('points', 0);
  };

  const handleUserTypeList = () => {
    const tempArr = userTypeDropdownList.map((ele) => {
      if (canUseAuthComponent(user.roles, ele.authorizedRoles)) {
        return ele;
      }
    }).filter(Boolean);
    // This .filter is clearing out undefineds that get genereated from the .map

    setFilteredUserTypeList(tempArr as ListMenuItem[]);
    if (params && params.userType) {
      setUserType(params.userType as UserRole);
    }
  };

  const getSchoolsForUser = () => {
    getOrganizationsByType(undefined, undefined, true).then(res => setAllSchoolsList(res));
  };

  const createGradYears = () => {
    const arr = [];
    for (let i = 0; i <= 6; i++) {
      arr.push({
        id: new Date().getFullYear() + i,
        label: (new Date().getFullYear() + i).toString(),
      });
    }
    setGradYearList(arr);
  };

  const handleUserAuth = () => {
    if (user.isAdmin || user.isDistrictAdmin) {
      return;
    }
    else if (user.isHighSchoolKeyContact || user.isHighSchoolCounselor) {
      setOrgType(OrganizationType.HighSchool);
    }
    else if (user.isMiddleSchoolKeyContact || user.isMiddleSchoolCounselor) {
      setOrgType(OrganizationType.MiddleSchool);
    }
    else if (user.isAdultKeyContact || user.isAdultCounselor) {
      setOrgType(OrganizationType.Adult);
    }
    else if (user.isUniversityKeyContact || user.isUniversityCounselor) {
      setOrgType(OrganizationType.UniversityCollege);
    }
  };

  const selectOrgType = (e: ListMenuItem) => {
    if (!e || !e.id) {
      setOrgType('');
      handleSchoolFieldDefaultValue();
      return;
    }

    if (e?.id && user.isKeyContact || user.isCounselor || user.isInstitutionAdmin && !user.isDistrictInstitutionAdmin) {
      setOrgType(e.id);
    }
    else if (e?.id && !user.isDistrictAdmin) {
      setOrgType(e.id);
      getOrganizationsByType(e.id as string, undefined, true).then(res => setAllSchoolsList(res));
    }
    else if (e?.id && user.isDistrictAdmin) {
      setOrgType(e.id);
      getOrganizationsByType(e.id as string, user.organizationId, true).then(res => setAllSchoolsList(res));
    }
    else {
      setOrgType('');
      handleSchoolFieldDefaultValue();
    }
  };

  const handleOrgTypeDefaultValue = () => {
    if (user.isDistrictInstitutionAdmin) {
      return organizationTypeList.find(e => e.id === OrganizationType.UniversityCollege);
    }
    else if (user.isKeyContact || user.isCounselor || user.isInstitutionAdmin) {
      if (organizationTypeList.some(_ => user.organization.type)) {
        return organizationTypeList.find(e => user.organization.type === e.id);
      }
    }
    else if (userType == UserRole.community) {
      return {
        id: OrganizationType.Community,
        label: 'Community Organization',
      };
    }
    else if (userType == UserRole.institutionAdmin) {
      return {
        id: OrganizationType.UniversityCollege,
        label: 'Higher Education',
      };
    }
  };

  const handleSchoolFieldDefaultValue = () => {
    if (user.isKeyContact || user.isCounselor || user.isInstitutionAdmin) {
      if (allSchoolsList.some(_ => user.organization)) {
        return allSchoolsList.find(e => user.organizationId === e.id);
      }
    }

    if ((user.isInstitutionAdmin && userType == UserRole.institutionAdmin)) {
      if (higherEdOrgList.some(_ => user.organization)) {
        return higherEdOrgList.find(e => user.organizationId === e.id);
      }
    }
  };

  const formValidationSchema = yup.object().shape({
    userType: yup.object()
      .required('User type is required')
      .typeError('User type is required'),
    gradYear: (userType == UserRole.student && !orgType) || (userType == UserRole.student && (orgType == OrganizationType.MiddleSchool || orgType == OrganizationType.HighSchool)) ?
      yup.object()
        .required('Graduation year is required')
        .typeError('Graduation year is required') :
      yup.object().nullable(),
    firstName: yup.string().required('First name is required').typeError('First name is required'),
    lastName: yup.string().required('Last name is required').typeError('First name is required'),
    parentEducation: (userType == UserRole.student && !orgType) || (userType == UserRole.student && (orgType == OrganizationType.MiddleSchool || orgType == OrganizationType.HighSchool)) ?
      yup.object()
        .required('Parent education level is required')
        .typeError('Parent education level is required') :
      yup.object().nullable(),
    educationLevel: (userType == UserRole.student && orgType == OrganizationType.Adult) ?
      yup.object()
        .required('Education level is required')
        .typeError('Education level is required') :
      yup.object().nullable(),
    gender: userType == UserRole.student ?
      yup.object()
        .required('Gender is required')
        .typeError('Gender is required') :
      yup.object().nullable(),
    orgType: (userType !== UserRole.districtAdmin && userType !== UserRole.admin && userType !== UserRole.ktsAdmin) ?
      yup.object()
        .required('Organization type is required')
        .typeError('Organization type is required') :
      yup.object().nullable(),
    organization: (userType !== UserRole.admin && userType !== UserRole.ktsAdmin) ?
      yup.object()
        .required('Organization is required')
        .typeError('Organization is required') :
      yup.object().nullable(),
    ethnicities: userType === UserRole.student ?
      yup.array()
        .min(1, 'Race / Ethnicity is required')
        .required('Race / Ethnicity is required')
        .typeError('Race / Ethnicity is required') :
      yup.array().nullable(),
    points: yup.number()
      .typeError(''),
    phoneNumber: yup.string()
      .test('len', 'Must be exactly 10 characters', (val?: string) => {
        return !val || val.length === 12 || val.length === 0;
      }).typeError(''),
    email: yup.string()
      .email('Must be a valid email')
      .required('Email is required')
      .typeError('Email is required'),
    password: yup.string()
      .required('Password is required')
      .isStrongPassword()
      .typeError('Password is required'),
  }).required();

  type FormValues = yup.InferType<typeof formValidationSchema>;
  const methods = useForm<FormValues>({
    resolver: yupResolver(formValidationSchema),
  });
  const { handleSubmit, setValue, formState: { errors } } = methods;

  const submitForm: SubmitHandler<FormValues> = async (values: FormValues) => {
    const newUserData = {
      ...values,
      roles: handleUserTypeSelection(values),
    };

    await dispatch(createNewUser(newUserData))
      .unwrap()
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: 'Suceesfully created user.',
        }));
        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: 'Unable to create new user.',
        }));
      });
  };

  const handleUserTypeSelection = (values: FormValues) => {
    if (values.userType.label == 'Student') {
      return [
        UserRole.student,
        `${values.orgType.id == OrganizationType.MiddleSchool ? UserRole.middleSchoolStudent :
          values.orgType.id == OrganizationType.HighSchool ? UserRole.highSchoolStudent : UserRole.adult}`,
      ];
    }
    else if (values.userType.label == 'Key Contact') {
      return [
        UserRole.keyContact,
        `${values.orgType.id == OrganizationType.MiddleSchool ? UserRole.middleSchoolKeyContact :
          values.orgType.id == OrganizationType.HighSchool ? UserRole.highSchoolKeyContact :
            values.orgType.id == OrganizationType.Adult ? UserRole.adultKeyContact : UserRole.universityKeyContact}`,
      ];
    }
    else if (values.userType.label == 'Counselor') {
      return [
        UserRole.counselor,
        `${values.orgType.id == OrganizationType.MiddleSchool ? UserRole.middleSchoolCounselor :
          values.orgType.id == OrganizationType.HighSchool ? UserRole.highSchoolCounselor :
            values.orgType.id == OrganizationType.Adult ? UserRole.adultCounselor : UserRole.universityCounselor}`,
      ];
    }
    else if (values.userType.label == 'Community') {
      return [
        UserRole.community,
      ];
    }
    else if (values.userType.label == 'District Admin') {
      return [
        UserRole.districtAdmin,
      ];
    }
    else if (values.userType.label == 'Institution Admin') {
      return [
        UserRole.keyContact,
        UserRole.universityKeyContact,
        UserRole.community,
        UserRole.institutionAdmin,
      ];
    }
    else {
      return [
        UserRole.admin,
      ];
    }
  };


  return (
    <div id="new-user-page" className="flex_col">
      <form className="create-form-container" onSubmit={handleSubmit(submitForm)}>
        <FormProvider {...methods}>
          <h3>New {startCase(userType)}</h3>
          <Divider />

          {userType == UserRole.student && (orgType == OrganizationType.MiddleSchool || orgType == OrganizationType.HighSchool) && (
            <div className="flex_row_jend_acenter">
              <div className="approval-status flex_row_acenter"
                onMouseUp={(e) => setAnchorElement(e.currentTarget)}>
                <p>Approval Status:</p>
                {approvalStatus == UserApprovalStatus.approved ? <img src={AppAssetPaths.icons.statusIcons.APPROVED} /> :
                  approvalStatus == UserApprovalStatus.pending ? <img src={AppAssetPaths.icons.statusIcons.PENDING} /> :
                    <img src={AppAssetPaths.icons.statusIcons.REJECTED} />}
                <Menu className="flex_row_jcenter_acenter"
                  anchorEl={anchorElement}
                  open={Boolean(anchorElement)}
                  onClose={() => setAnchorElement(null)}
                  autoFocus={false}
                  PaperProps={defaultMenuPaperProps}
                  transformOrigin={topCenterTransformOrigin}
                  anchorOrigin={bottomCenterAnchorOrigin}
                >
                  <MenuItem onClick={() => (setAnchorElement(null), setApprovalStatus(UserApprovalStatus.approved))}>
                    Approved
                  </MenuItem>
                  <MenuItem onClick={() => (setAnchorElement(null), setApprovalStatus(UserApprovalStatus.pending))}>
                    Pending
                  </MenuItem>
                  <MenuItem onClick={() => (setAnchorElement(null), setApprovalStatus(UserApprovalStatus.rejected))}>
                    Rejected
                  </MenuItem>
                </Menu>
              </div>
            </div>
          )}

          <h4>General Information</h4>
          <div className={`general-forms flex_col_jbetween ${userType.replace(/_/g, '-')}-form`}>

            <DropdownSelect
              inputClass="form-input"
              name="userType"
              label="USER TYPE"
              itemList={filteredUserTypeList}
              errorMessage={errors.userType && errors.userType.message}
              onChange={(e) => setUserType(snakeCase(e.label) as UserRole)}
              disableClearable
              size="small"
              required
            />

            <TextInput
              inputClass="form-input"
              name="firstName"
              label="FIRST NAME"
              errorMessage={errors.firstName && errors.firstName.message}
              type="text"
              size="small"
              required
            />

            <TextInput
              inputClass="form-input"
              name="lastName"
              label="LAST NAME"
              errorMessage={errors.lastName && errors.lastName.message}
              type="text"
              size="small"
              required
            />

            {userType == UserRole.districtAdmin && (
              <DropdownSelect
                inputClass="form-input"
                name="organization"
                label="DISTRICT"
                itemList={schoolDistrictList}
                errorMessage={errors.organization && errors.organization.message}
                size="small"
                required
              />
            )}

            {(userType !== UserRole.districtAdmin && userType !== UserRole.admin && userType !== UserRole.ktsAdmin) && (
              <DropdownSelect
                inputClass="form-input"
                name="orgType"
                label="ORGANIZATION TYPE"
                itemList={organizationTypeList.filter(o => o.id !== OrganizationType.Community && o.id !== OrganizationType.NoSchool)}
                errorMessage={errors.orgType && errors.orgType.message}
                onChange={(e) => selectOrgType(e)}
                disabled={user.isKeyContact || user.isCounselor || user.isInstitutionAdmin || userType == UserRole.community || userType == UserRole.institutionAdmin}
                size="small"
                required
              />
            )}

            {(userType == UserRole.community || userType == UserRole.institutionAdmin) && (
              <DropdownSelect
                inputClass="form-input"
                name="organization"
                label="ORGANIZATION"
                itemList={userType === UserRole.community ? communityList : higherEdOrgList}
                errorMessage={errors.organization && errors.organization.message}
                disabled={(user.isInstitutionAdmin && !user.isDistrictAdmin)}
                size="small"
                required
              />
            )}

            {(userType !== UserRole.districtAdmin && userType !== UserRole.admin && userType !== UserRole.community && userType !== UserRole.institutionAdmin && userType !== UserRole.ktsAdmin) && (
              <DropdownSelect
                inputClass="form-input"
                name="organization"
                label="SCHOOL"
                itemList={allSchoolsList}
                errorMessage={errors.organization && errors.organization.message}
                disabled={(!orgType || user.isKeyContact || user.isCounselor || user.isInstitutionAdmin) && !user.isDistrictInstitutionAdmin}
                size="small"
                required
              />
            )}

            {(userType == UserRole.student) && (orgType === OrganizationType.MiddleSchool || orgType === OrganizationType.HighSchool) && (
              <DropdownSelect
                inputClass="form-input"
                name="gradYear"
                label="HIGH SCHOOL GRADUATION YEAR"
                itemList={gradYearList}
                errorMessage={errors.gradYear && errors.gradYear.message}
                size="small"
                required
              />
            )}

            {userType == UserRole.student && (
              (orgType == OrganizationType.MiddleSchool || orgType == OrganizationType.HighSchool || !orgType) ? (
                <DropdownSelect
                  inputClass="form-input"
                  name="parentEducation"
                  label="PARENTS LEVEL OF EDUCATION"
                  itemList={educationLevels}
                  errorMessage={errors?.parentEducation?.message}
                  size="small"
                  required
                />
              ) : (
                <DropdownSelect
                  inputClass="form-input"
                  name="educationLevel"
                  label="LEVEL OF EDUCATION"
                  itemList={educationLevels}
                  errorMessage={errors.educationLevel && errors.educationLevel.message}
                  size="small"
                  required
                />
              )
            )}

            {userType == UserRole.student && (
              <DropdownSelect
                inputClass="form-input"
                name="gender"
                label="GENDER"
                itemList={genders}
                errorMessage={errors.gender && errors.gender.message}
                size="small"
                required
              />
            )}

            {userType == UserRole.student && (
              <DropdownSelect
                inputClass="form-input"
                name="ethnicities"
                label="RACE/ETHNICITY"
                itemList={ethnicities}
                errorMessage={errors?.ethnicities?.message}
                size="small"
                required
                multiple
              />
            )}

            <TextInput
              inputClass="form-input"
              name="phoneNumber"
              label="PHONE NUMBER"
              errorMessage={errors.phoneNumber && errors.phoneNumber.message}
              type="text"
              size="small"
              maxLength={12}
              onKeyPress={(e) => {
                numberOnlyValidation(e);
                if (e.keyCode || e.keyCode == 0) {
                  e.target.value = formatPhone(e.target.value);
                }
              }}
            />

            {(userType == UserRole.student && (orgType === OrganizationType.MiddleSchool || orgType === OrganizationType.HighSchool || !orgType)) && (
              <TextInput
                inputClass="form-input"
                name="points"
                label="POINTS"
                type="number"
                size="small"
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            )}
          </div>

          <h4>Sign In Credentials</h4>
          <div className="general-forms bottom-section flex_row_jbetween">

            <TextInput
              inputClass="form-input"
              name="email"
              label="EMAIL"
              errorMessage={errors.email && errors.email.message}
              type="text"
              size="small"
              required
            />

            <TextInput
              inputClass="form-input"
              name="password"
              label="PASSWORD"
              errorMessage={errors.password && errors.password.message}
              type="password"
              autoComplete="new-password"
              size="small"
              required
            />

          </div>
          <div className="flex_row_jbetween">
            <Button
              className="form-button"
              variant="outlined"
              onClick={() => navigate(AppRoutes.users.path)}>
              cancel
            </Button>
            <SubmitButton
              className="form-button"
              text="Save"
              variant="contained"
              size="large"
              disabled={createNewUserStatus === StateStatus.LOADING}
              disableElevation
            />
          </div>
        </FormProvider>
      </form>
    </div>
  );
};

export default NewUser;
