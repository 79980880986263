/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridSortModel } from '@mui/x-data-grid';
import { ListMenuItem } from '../../components/forms/form.types';
import { CareerGroup } from '../careers/careers.model';
import { getSchools } from '../organization/organization.api';
import {
  ConstructOrganization, Organization, OrganizationType, postSecondaryDistrict
} from '../organization/organization.model';
import {
  ConstructResource, Resource
} from '../resources/resources.model';
import {
  ConstructScholarship, Scholarship
} from '../scholarship/scholarship.model';
import {
  TransferApproval, ConstructTransferApproval
} from '../approvals/approvals.model';
import {
  getEducationLevels, getEthnicities, getGenders
} from './user.api';


export const deletedUser = 'deleted_user';

export enum UserRole {
  admin = 'admin',
  districtAdmin = 'district_admin',
  keyContact = 'key_contact',
  middleSchoolKeyContact = 'middle_school_key_contact',
  highSchoolKeyContact = 'high_school_key_contact',
  adultKeyContact = 'adult_key_contact',
  universityKeyContact = 'university_key_contact',
  counselor = 'counselor',
  middleSchoolCounselor = 'middle_school_counselor',
  highSchoolCounselor = 'high_school_counselor',
  adultCounselor = 'adult_counselor',
  universityCounselor = 'university_counselor',
  community = 'community',
  student = 'student',
  middleSchoolStudent = 'middle_school_student',
  highSchoolStudent = 'high_school_student',
  adult = 'adult',
  /*
    * institution_admin is a combination of key_contact and community
    * Until something changes, the exact role array should should contain these: ['key_contact', 'university_key_contact', 'community', 'institution_admin']
    * For most instances, you need to check for one or both of these if you want to specify an institution_admin
   */
  institutionAdmin = 'institution_admin',
  /*
    * institution_district_admin is a combination of district_admin and community
    * The role array should contain these: ['district_admin', 'community', 'institution_admin']
   */
  institutionDistrictAdmin = 'institution_district_admin',
  /* kts_admin converted to 'admin' during in NewUser.ts */
  ktsAdmin = 'kts_admin',
}

export interface User {
  id?: number;
  firstname: string;
  lastname: string;
  fullname?: string;
  email: string;
  organizationId: number;
  organization: Organization;
  graduationYear?: number;
  roles: UserRole[];
  userType?: UserRole;
  phoneNumber?: string;
  educationLevelId?: number;
  parentEducationLevelId?: number;
  genderId?: number;
  ethnicityIds?: number[];
  points?: number;
  scholarships?: Scholarship[];
  opportunities?: Resource[];
  status?: {
    emailVerified?: boolean;
    accountApproved?: boolean;
    approvalId?: number;
    partialRegistration?: boolean;
  }
  transferRequests?: TransferApproval[];
  createdAt?: string;
  deletedAt?: string;
  lastLogin?: string;

  // User Type booleans
  isStudent?: boolean;
  isAdmin?: boolean;
  isDistrictAdmin?: boolean;
  isInstitutionAdmin?: boolean;
  isKeyContact?: boolean;
  isCounselor?: boolean;
  isCommunity?: boolean;
  isMiddleSchoolAdmin?: boolean;
  isMiddleSchoolKeyContact?: boolean;
  isMiddleSchoolCounselor?: boolean;

  isHighSchoolAdmin?: boolean;
  isHighSchoolKeyContact?: boolean;
  isHighSchoolCounselor?: boolean;

  isAdultAdmin?: boolean;
  isAdultKeyContact?: boolean;
  isAdultCounselor?: boolean

  isUniversityKeyContact?: boolean;
  isUniversityCounselor?: boolean;

  isDistrictInstitutionAdmin?: boolean;
}

export const emptyUser: User = {
  id: -1,
  email: '',
  firstname: '',
  lastname: '',
  organizationId: 0,
  organization: undefined as unknown as Organization,
  roles: [],
};

export interface SignupUser {
  organizationName: string;
  organizationType: ListMenuItem;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber?: number;
  careerGroups: CareerGroup[];
  logo?: FileList;
  intendedParticipation?: {
    scholarships: boolean;
    internships: boolean;
    openHouses: boolean;
    jobShadowing: boolean;
    other: boolean;
  }
  heardAboutKts?: ListMenuItem;
}

export interface NewCreateUser {
  firstName: string;
  lastName: string;
  userType: any;
  gradYear: any;
  educationLevel: any;
  parentEducation: any;
  gender: any;
  ethnicities: ListMenuItem[];
  orgType: any;
  school: any;
  email: string;
  password: string;
  roles: string[];
  points: number;
  phoneNumber: string;
  organization: Organization;
  id?: number;
}

export interface UserListQueryParams {
  offset: number;
  limit?: number;
  role?: string;
  search?: string;
  schoolType?: string;
  schoolDistricts?: number[];
  graduationYears?: number[];
  sortModel?: GridSortModel;
}

export enum UserApprovalStatus {
  pending = 'pending',
  rejected = 'rejected',
  approved = 'approved',
  unverified = 'unverified',
  active = 'active',
  partialRegistration = 'partial_registration'
}

export interface UserApproval {
  organizationId: number;
  approved: boolean;
}

export interface UserOverview {
  isStudent: boolean,
  parentEducation?: string,
  organizationType?: OrganizationType,
  gender?: string,
  schoolName?: string,
  ethnicities?: ListMenuItem[],
  graduationYear?: number,
  points?: number,
  organizationId?: number;
  organizationName?: string;
  phoneNumber?: string;
}

export const EmptyUserOverview: UserOverview = {
  isStudent: false,
};

export interface GradeEducationValues {
  'middle_school': ListMenuItem[];
  'high_school': ListMenuItem[];
  'adult_school': ListMenuItem[];
}

export const gradeEducationValuesListOptions: GradeEducationValues = {
  'middle_school': [
    {
      id: 6,
      label: '6th',
    },
    {
      id: 7,
      label: '7th',
    },
    {
      id: 8,
      label: '8th',
    },
    {
      id: 9,
      label: '9th',
    },
  ],
  'high_school': [
    {
      id: 9,
      label: '9th',
    },
    {
      id: 10,
      label: '10th',
    },
    {
      id: 11,
      label: '11th',
    },
    {
      id: 12,
      label: '12th',
    },
  ],
  'adult_school': [
    {
      id: 'adult_school',
      label: 'Adult Education',
    },
    {
      id: 'university_college',
      label: 'Higher Education',
    },
    {
      id: 'no_school',
      label: 'No School Associated',
    },
  ],
};

export const ConstructUser = (data: any): User => {
  const roles: UserRole[] = data.roles || [];

  return {
    id: data.id,
    email: data.email,
    firstname: data.first_name,
    lastname: data.last_name,
    fullname: (data.first_name || data.last_name) && `${data.first_name || ''} ${data.last_name || ''}`,
    organizationId: data.organization_id,
    organization: data.organization && ConstructOrganization({
      id: data.organization_id,
      ...data.organization,
    }),
    graduationYear: data.graduation_year,
    roles,
    userType: getUserTypeByRole(roles),
    phoneNumber: data.address ? data.address.phone_number : data.addresses?.length ? data.addresses[0].phone_number : null,
    educationLevelId: data.education_level_id,
    parentEducationLevelId: data.parent_education_level_id,
    genderId: data.gender_id,
    ethnicityIds: data.ethnicities && !!data.ethnicities.length ? data.ethnicities.map((e: any) => e.ethnicity_id) : null,
    points: data.points,
    scholarships: data.scholarships && data.scholarships.map((s: any) => ConstructScholarship(s)),
    opportunities: data.opportunities && data.opportunities.map((o: any) => ConstructResource(o)),
    status: {
      emailVerified: data.user_approval && !!data.user_approval.length ? data.user_approval[0].email_verified : undefined,
      accountApproved: data.student_approvals && !!data.student_approvals.length ? data.student_approvals[0].approved : undefined,
      approvalId: data.student_approvals && !!data.student_approvals.length ? data.student_approvals[0].id : undefined,
      partialRegistration: data.user_approval && !!data.user_approval.length ? data.user_approval[0].partial_registration : undefined,
    },
    transferRequests: data.transfer_requests ? data.transfer_requests.map((item: any) => ConstructTransferApproval(item)) : [],
    createdAt: data.created_at,
    deletedAt: data.deleted_at,
    lastLogin: data.last_login,

    isStudent: roles.includes(UserRole.student),
    isAdmin: roles.includes(UserRole.admin),
    isDistrictAdmin: roles.includes(UserRole.districtAdmin),
    isInstitutionAdmin: roles.includes(UserRole.institutionAdmin),
    isKeyContact: roles.includes(UserRole.keyContact),
    isCounselor: roles.includes(UserRole.counselor),
    isCommunity: roles.includes(UserRole.community),

    isMiddleSchoolAdmin: roles.includes(UserRole.middleSchoolKeyContact) || roles.includes(UserRole.middleSchoolCounselor),
    isMiddleSchoolKeyContact: roles.includes(UserRole.middleSchoolKeyContact),
    isMiddleSchoolCounselor: roles.includes(UserRole.middleSchoolCounselor),

    isHighSchoolAdmin: roles.includes(UserRole.highSchoolKeyContact) || roles.includes(UserRole.highSchoolCounselor),
    isHighSchoolKeyContact: roles.includes(UserRole.highSchoolKeyContact),
    isHighSchoolCounselor: roles.includes(UserRole.highSchoolCounselor),

    isAdultAdmin: roles.includes(UserRole.adultKeyContact) || roles.includes(UserRole.adultCounselor) || roles.includes(UserRole.universityKeyContact) || roles.includes(UserRole.universityCounselor),
    isAdultKeyContact: roles.includes(UserRole.adultKeyContact),
    isAdultCounselor: roles.includes(UserRole.adultCounselor),

    isUniversityKeyContact: roles.includes(UserRole.universityKeyContact),
    isUniversityCounselor: roles.includes(UserRole.universityCounselor),

    isDistrictInstitutionAdmin: roles.includes(UserRole.districtAdmin) && roles.includes(UserRole.institutionAdmin) && (roles.includes(UserRole.community) || data?.organization?.name === postSecondaryDistrict) || roles.includes(UserRole.institutionDistrictAdmin),
  };
};

export const ConstructUserOverview = async (user: User): Promise<UserOverview> => {
  const isStudent = user.roles.includes(UserRole.student);
  const schools = await getSchools();
  const edMap = isStudent ? await getEducationLevels() : undefined;
  const genders = isStudent ? await getGenders() : undefined;
  const ethnicities = await getEthnicities();

  return {
    isStudent,
    parentEducation: edMap?.filter(lv => lv.id === user.parentEducationLevelId)[0]?.label,
    organizationType: getUserSchoolType(user.roles, user.organization?.type) || user.organization?.type,
    organizationId: user.organizationId,
    gender: genders?.filter(gen => gen.id === user.genderId)[0]?.label,
    schoolName: schools.filter(s => s.id === user.organizationId)[0]?.name,
    organizationName: user.organization?.name,
    ethnicities: ethnicities?.filter(e => user.ethnicityIds?.some((x: number) => e.id === x)),
    graduationYear: user.graduationYear,
    points: user.points,
    phoneNumber: user.phoneNumber,
  };
};

export const getGenericUserType = (roles: UserRole[]): string | undefined => {
  if (
    roles.includes(UserRole.districtAdmin) ||
    roles.includes(UserRole.admin)
  ) {
    return UserRole.admin;
  }
  else if (roles.includes(UserRole.institutionAdmin)) {
    return UserRole.institutionAdmin.split('_')[0];
  }
  else if (roles.includes(UserRole.keyContact)) {
    return UserRole.keyContact;
  }
  else if (roles.includes(UserRole.counselor)) {
    return UserRole.counselor;
  }
  else if (roles.includes(UserRole.adult)) {
    return UserRole.adult;
  }
  else if (roles.includes(UserRole.student)) {
    return UserRole.student;
  }
};

export const getUserSchoolType = (roles: UserRole[], orgType?: OrganizationType): OrganizationType | undefined => {

  if (orgType) return orgType;

  if (
    roles.includes(UserRole.highSchoolKeyContact) ||
    roles.includes(UserRole.highSchoolCounselor) ||
    roles.includes(UserRole.highSchoolStudent)
  ) {
    return OrganizationType.HighSchool;
  }
  else if (
    roles.includes(UserRole.middleSchoolKeyContact) ||
    roles.includes(UserRole.middleSchoolCounselor) ||
    roles.includes(UserRole.middleSchoolStudent)
  ) {
    return OrganizationType.MiddleSchool;
  }
  else if (
    roles.includes(UserRole.adultKeyContact) ||
    roles.includes(UserRole.adultCounselor) ||
    roles.includes(UserRole.adult)
  ) {
    return OrganizationType.Adult;
  }
  else if (
    roles.includes(UserRole.universityKeyContact) ||
    roles.includes(UserRole.universityCounselor) ||
    roles.includes(UserRole.institutionAdmin)
  ) {
    return OrganizationType.UniversityCollege;
  }
};

export const getUserStatus = (approved?: boolean, emailVerified?: boolean, partialRegistration?: boolean): UserApprovalStatus => {
  if (partialRegistration) {
    return UserApprovalStatus.partialRegistration;
  }
  else if (!emailVerified && !approved) {
    return UserApprovalStatus.pending;
  }
  else if (!emailVerified && approved) {
    return UserApprovalStatus.unverified;
  }
  else if (emailVerified && approved) {
    return UserApprovalStatus.approved;
  }
  else if (approved === false) {
    return UserApprovalStatus.rejected;
  }
  else { return UserApprovalStatus.pending; }
};

export const getUserTypeByRole = (roles: UserRole[]): UserRole | undefined => {
  if (roles.includes(UserRole.admin)) {
    return UserRole.admin;
  }
  else if (roles.includes(UserRole.districtAdmin)) {
    return UserRole.districtAdmin;
  }
  else if (roles.includes(UserRole.institutionAdmin)) {
    return UserRole.institutionAdmin;
  }
  else if (roles.includes(UserRole.keyContact)) {
    return UserRole.keyContact;
  }
  else if (roles.includes(UserRole.counselor)) {
    return UserRole.counselor;
  }
  else if (roles.includes(UserRole.student)) {
    return UserRole.student;
  }
  else if (roles.includes(UserRole.community)) {
    return UserRole.community;
  }
};
