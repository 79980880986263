/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import isBetween from 'dayjs/plugin/isBetween';
import dayjs from 'dayjs';
dayjs.extend(isBetween);
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  ConstructStudentApproval,
  StudentApproval,
  ApprovalListQueryParams,
  SkillTrainingApproval,
  ConstructSkillTrainingApproval,
  ConstructTransferApproval,
  TransferApproval,
  ConstructNotificationApproval,
  NotificationApproval,
  ConstructResourceApproval,
  ResourceApproval,
  ConstructApplicationApproval,
  ApplicationApproval
} from './approvals.model';


export const getStudentApprovalList = async (query: ApprovalListQueryParams): Promise<{ approvals: StudentApproval[], count: number }> => {

  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  const studentTypeParam = query.userType ? `&studentType=${query.userType}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';
  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('school', 'organization');

  const url = `/approvals/student/?offset=${query.offset}&limit=${limitValue}${searchParam}${studentTypeParam}${orderBy}${orderDirecton}`;
  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const approvals = data.data.map((r: any) => ConstructStudentApproval(r));

  return {
    approvals,
    count: data.count,
  };
};

export const getTransferApprovalList = async (query: ApprovalListQueryParams): Promise<{ approvals: TransferApproval[], count: number }> => {

  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  const transferTypeParam = query.userType ? `&transferType=${query.userType}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';
  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('currentSchool', 'transferring_from');
  orderBy = orderBy.replace('newSchool', 'transferring_to');

  const url = `/approvals/transfer/?offset=${query.offset}&limit=${limitValue}${searchParam}${transferTypeParam}${orderBy}${orderDirecton}`;
  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const approvals = data.data.map((r: any) => ConstructTransferApproval(r));

  return {
    approvals,
    count: data.count,
  };
};

export const getSkillTrainingApprovalList = async (query: ApprovalListQueryParams): Promise<{ approvals: SkillTrainingApproval[], count: number }> => {

  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';
  orderBy = orderBy.replace('createdAt', 'created_at');

  const url = `/approvals/skill-trainings/?offset=${query.offset}&limit=${limitValue}${searchParam}${orderBy}${orderDirecton}`;
  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const approvals = data.data.map((a: any) => ConstructSkillTrainingApproval(a));

  return {
    approvals: approvals,
    count: data.count,
  };
};


export const getNotificationApprovalList = async (query: ApprovalListQueryParams): Promise<{ approvals: NotificationApproval[], count: number }> => {

  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';
  const schoolTypeParam = query.schoolType ? `&schoolType=${query.schoolType}` : '';
  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('title', 'subject');
  orderBy = orderBy.replace('creator', 'created_by');
  orderBy = orderBy.replace('school', 'organization');
  orderBy = orderBy.replace('status', 'approved');

  const url = `/approvals/notification/?offset=${query.offset}&limit=${limitValue}${schoolTypeParam}${searchParam}${orderBy}${orderDirecton}`;
  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const allCareerGroups = await getCareerGroupTitles();
  const approvals = data.data.map((r: any) => ConstructNotificationApproval(r, allCareerGroups));

  return {
    approvals,
    count: approvals.length,
  };
};

export const getResourceApprovalList = async (query: ApprovalListQueryParams): Promise<{ approvals: ResourceApproval[], count: number }> => {

  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';
  const schoolTypeParam = query.schoolType ? `&schoolType=${query.schoolType}` : '';
  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('creator', 'created_by');

  const url = `/approvals/resource/?offset=${query.offset}&limit=${limitValue}${schoolTypeParam}${searchParam}${orderBy}${orderDirecton}`;
  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const approvals = data.data.map((r: any) => ConstructResourceApproval(r));

  return {
    approvals,
    count: approvals.length,
  };
};

export const getApplicationApprovalList = async (query: ApprovalListQueryParams): Promise<{ approvals: ApplicationApproval[], count: number }> => {

  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';
  const schoolTypeParam = query.schoolType ? `&schoolType=${query.schoolType}` : '';
  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('school', 'organization');
  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('graduationYear', 'graduation_year');
  const url = `/approvals/application/?offset=${query.offset}&limit=${limitValue}${schoolTypeParam}${searchParam}${orderBy}${orderDirecton}`;
  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const approvals = data.data.map((r: any) => ConstructApplicationApproval(r));
  return {
    approvals,
    count: data.count,
  };
};

const getCareerGroupTitles = async () => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/career-group');
  return data.data.map((career: any) => {
    return {
      id: career.id,
      title: career.title,
    };
  });
};
