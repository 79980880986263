/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  Applicant,
  ApplicantListQueryParams,
  College,
  CollegeListQueryParams,
  ConstructApplicant,
  ConstructCollege,
  ConstructCollegeForApi,
  ConstructFafsaLink,
  ConstructFinancialAidInfoSection,
  ConstructInstitutionType,
  FafsaLink,
  FafsaLinks,
  FinancialAidInfoSection,
  InstitutionType
} from './collegeApplicationChecklist.model';


export const getApplicantsList = async (query: ApplicantListQueryParams): Promise<{ applicants: Applicant[], count: number }> => {
  const offsetValue = query.offset || 0;
  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';
  const filterByCollege = query.colleges && !!query.colleges.length ? `&filter_by_college=${query.colleges}` : '';
  const filterByApplicationInterested = query.applicationInterested ? `&filter_by_applicationInterested=${query.applicationInterested}` : '';

  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('fullName', 'full_name');
  orderBy = orderBy.replace('institutionName', 'institution_name');
  orderBy = orderBy.replace('organizationName', 'organization_name');
  orderBy = orderBy.replace('graduationYear', 'graduation_year');

  const url = `/college-application-checklist/applicants?offset=${offsetValue}&limit=${limitValue}${searchParam}${orderBy}${orderDirecton}${filterByCollege}${filterByApplicationInterested}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  return {
    applicants: data.data.map((applicant: any) => ConstructApplicant(applicant)),
    count: data.count,
  };
};

export const getCollegesList = async (query: CollegeListQueryParams): Promise<{ colleges: College[], count: number }> => {
  const offsetValue = query.offset || 0;
  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  orderBy = orderBy.replace('updatedAt', 'updated_at');
  orderBy = orderBy.replace('type', 'institution_type_id');

  const url = `/institutions?offset=${offsetValue}&limit=${limitValue}${searchParam}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.POST, url);

  return {
    colleges: data.data.map((college: any) => ConstructCollege(college)),
    count: data.count,
  };
};

export const getAllInstitutionTypes = async (): Promise<InstitutionType[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/institution-types');

  return data.data.map((instiType: any) => ConstructInstitutionType(instiType));
};

export const getCollegeById = async (id: number): Promise<College> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, `/institutions/${id}`);
  return ConstructCollege(data.data);
};

export const getFinancialAidSections = async (): Promise<FinancialAidInfoSection[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/college-application-checklist/financial-aid-sections');
  return data.data.map((section: any) => ConstructFinancialAidInfoSection(section));
};

export const updateFinancialAidSections = async (sections: FinancialAidInfoSection[]): Promise<FinancialAidInfoSection[]> => {
  const body = sections.map(s => ({
    id: s.sectionId,
    name: s.name,
    description: s.description,
    url: s.url,
  }));

  const { data } = await requestFactory(HTTP_METHOD.PATCH, '/college-application-checklist/financial-aid-sections', undefined, body);
  return data.data.map((section: any) => ConstructFinancialAidInfoSection(section));
};

export const getFafsaLinks = async (): Promise<FafsaLink[]> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, '/college-application-checklist/fafsa-links');
  return data.data.map((link: any) => ConstructFafsaLink(link));
};

export const updateFafsaLinks = async (links: FafsaLinks): Promise<FafsaLink[]> => {
  const body = Object.values(links);
  const { data } = await requestFactory(HTTP_METHOD.PATCH, '/college-application-checklist/fafsa-links', undefined, body);
  return data.data.map((link: any) => ConstructFafsaLink(link));
};


export const createCollege = async (body: any) => {
  const returnVal = ConstructCollegeForApi(body);

  const { data } = await requestFactory(HTTP_METHOD.POST, '/institutions/create', undefined, returnVal);
  if (body.logo && !!body.logo.length) {
    await uploadCollegeLogo(data.data.id, body.logo[0]);
  }
  return data.data;
};

export const updateCollege = async (id: number, body: any) => {
  const returnVal = ConstructCollegeForApi(body);

  const { data } = await requestFactory(HTTP_METHOD.PATCH, `/institutions/update/${id}`, undefined, returnVal);
  if (body.logo && !!body.logo.length) {
    await uploadCollegeLogo(data.data[1][0].id, body.logo[0]);
  }
  return data.data;
};

export const uploadCollegeLogo = async (id: string, image: string | Blob) => {
  const newData = new FormData();
  newData.append('files', image);
  newData.append('resource_id', id);
  newData.append('resource_name', 'college');

  const response = await requestFactory(HTTP_METHOD.POST, '/assets/upload', undefined, newData);
  return await response.data;
};
