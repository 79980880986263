/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  ConstructInquiry, Inquiry, InquiryListQueryParams
} from './inquiries.model';


export const getInquiryList = async (query: InquiryListQueryParams): Promise<{ inquiries: Inquiry[], count: number }> => {

  const offsetValue = query.offset || 0;
  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('type', 'type_id');
  orderBy = orderBy.replace('phoneNumber', 'phone_number');

  const url = `/support-requests?offset=${offsetValue}&limit=${limitValue}${searchParam}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const inquiries = data.data.map((inquiry: any) => ConstructInquiry(inquiry));

  return {
    inquiries,
    count: data.count,
  };
};
