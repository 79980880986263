/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  Notification,
  NotificationListQueryParams,
  ConstructNotification
} from './notifications.model';

export const getNotificationList = async (query: NotificationListQueryParams): Promise<{ notifications: Notification[], count: number }> => {
  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  let organizationParam = '';
  if (query.schoolId) {
    organizationParam = `&organization_id=${query.schoolId}`;
  } else if (query.schoolDistrictId) {
    organizationParam = `&organization_id=${query.schoolDistrictId}&filter_by_district=true`;
  }

  const isInApp = query.inApp ? '&in_app=true' : '';
  const isPush = query.push ? '&is_push=true' : '';

  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  orderBy = orderBy.replace('createdAt', 'created_at');

  const url = `/notifications/all?offset=${query.offset}&limit=${limitValue}${searchParam}${organizationParam}${orderBy}${orderDirecton}${isInApp}${isPush}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const notifications = data.data.map((notification: any) => ConstructNotification(notification));

  return {
    notifications,
    count: data.count,
  };
};

export const getNotificationById = async (id: number): Promise<Notification> => {
  const response = await requestFactory(HTTP_METHOD.GET, `/notifications/${id}`);

  const data = await response.data.data;

  const notification = ConstructNotification(data);

  return notification;
};

export const createNotification = async (values: Notification): Promise<void> => {
  const body = {
    body: values.body,
    career_group_ids: values.careerGroupIds,
    gender_ids: values.genderIds,
    graduation_years: values.graduationYears?.sort(),
    organization_ids: values.organizationIds,
    organization_types: values.organizationTypes,
    school_district_ids: values.schoolDistrictIds,
    subject: values.subject,
    user_type_ids: values.userTypeIds,
    ethnicity_ids: values.ethnicityIds,
    parent_education_level_ids: values.parentEducationLevelIds,
    is_push: values.isPush,
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/notifications', undefined, body);

  return await response.data;
};

export const updateNotification = async (values: Notification): Promise<void> => {
  const body = {
    body: values.body,
    career_group_ids: values.careerGroupIds,
    gender_ids: values.genderIds,
    graduation_years: values.graduationYears?.sort(),
    organization_ids: values.organizationIds,
    organization_types: values.organizationTypes,
    school_district_ids: values.schoolDistrictIds,
    subject: values.subject,
    user_type_ids: values.userTypeIds,
    ethnicity_ids: values.ethnicityIds,
    parent_education_level_ids: values.parentEducationLevelIds,
  };

  const response = await requestFactory(HTTP_METHOD.PATCH, `/notifications/${values.id}`, undefined, body);

  return await response.data;
};

export const deleteNotification = async (id: number): Promise<boolean> => {
  await requestFactory(HTTP_METHOD.DELETE, `/notifications/${id}`);
  return true;
};

export const approveNotification = async (id: number): Promise<{ id: number, approved: boolean }> => {
  const response = await requestFactory(HTTP_METHOD.PATCH, `/notifications/${id}/approve`);
  const data = await response.data;

  return {
    id,
    approved: data.approved,
  };
};

export const rejectNotification = async (id: number): Promise<number> => {
  await requestFactory(HTTP_METHOD.DELETE, `/notifications/${id}/reject`);
  return id;
};
