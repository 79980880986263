/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  ConstructSkill, Skill, SkillsListQueryParams
} from './skills.model';


export const getSkillsList = async (query: SkillsListQueryParams): Promise<{ skills: Skill[], count: number }> => {

  const offsetValue = query.offset || 0;
  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = orderBy.replace('label', 'name');
  orderBy = orderBy.replace('status', 'active');

  const url = `/skillup-courses/skills?offset=${offsetValue}&limit=${limitValue}${searchParam}${orderBy}${orderDirecton}`;


  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const skills = data.data.map((skill: any) => ConstructSkill(skill));
  return {
    skills,
    count: data.count,
  };
};

export const getSkillById = async (id: number): Promise<Skill> => {
  const { data } = await requestFactory(HTTP_METHOD.GET, `/skillup-courses/skills/${id}`);
  return await ConstructSkill(data.data);
};


export const createSkill = async (body: { name: string, active: boolean }): Promise<Skill> => {
  const { data } = await requestFactory(HTTP_METHOD.POST, '/skillup-courses/skills', undefined, body);
  return await ConstructSkill(data.data);
};

export const updateSkill = async (id: number, body: { name: string, active: boolean }): Promise<Skill> => {
  const { data } = await requestFactory(HTTP_METHOD.PATCH, `/skillup-courses/skills/${id}`, undefined, body);
  return await ConstructSkill(data.data);
};

export const deleteSkill = async (id: number): Promise<void> => {
  await requestFactory(HTTP_METHOD.DELETE, `/skillup-courses/skills/${id}`);
};
