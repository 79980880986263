/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HTTP_METHOD, requestFactory
} from '../../app/app.api';
import {
  BooleanListMenuItem, ConstructListMenuItem
} from '../../components/forms/form.types';
import { OrganizationType } from '../organization/organization.model';
import { UserRole } from '../user/user.model';
import {
  ConstructPathway, NewPathway, Pathway, PathwayCourse, PathwayListQueryParams, PathwayType
} from './pathways.model';


export const getPathwayTypeList = async (): Promise<PathwayType[]> => {
  const { data } = await requestFactory(HTTP_METHOD.POST, '/pathway-type');

  return data.data.map((p: any) => ConstructListMenuItem(p.id, p.title));
};

// Get all pathways
export const getPathwayList = async (query: PathwayListQueryParams): Promise<{ pathways: Pathway[], count: number }> => {

  let url = '/pathway';

  if (query.role === UserRole.districtAdmin) {
    url = `${url}/district-admin`;
  }
  else if (query.role === OrganizationType.MiddleSchool) {
    url = `${url}/middle-school-admin`;
  }
  else if (query.role === OrganizationType.HighSchool) {
    url = `${url}/high-school-admin`;
  }
  else {
    url = `${url}/admin`;
  }

  const limitValue = query.limit || 100;
  const searchParam = query.search ? `&search=${query.search.trim()}` : '';
  const typeIdParam = query.typeId ? `&type_id=${query.typeId}` : '';
  const schoolIdParam = query.schoolId ? `&organization_id=${query.schoolId}` : '';
  let orderBy = query.sortModel?.length ? `&order_by=${query.sortModel[0].field}` : '';
  const orderDirecton = query.sortModel?.length ? `&order_direction=${query.sortModel[0].sort}` : '';

  // Convert camelcase values to underscore to match database
  orderBy = orderBy.replace('pathwayCluster', 'career_cluster');
  orderBy = orderBy.replace('createdAt', 'created_at');
  orderBy = query.role === UserRole.admin && !query.schoolId ? orderBy.replace('status', 'active') : orderBy.replace('status', 'active_for_school');

  url = `${url}?offset=${query.offset}&limit=${limitValue}${searchParam}${typeIdParam}${schoolIdParam}${orderBy}${orderDirecton}`;

  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  const pathways = data.data.map((p: any) => ConstructPathway(p));

  return {
    pathways,
    count: data.count,
  };
};

// Get pathway details
export const getPathwayDetails = async (id: number, organizationId?: number): Promise<Pathway> => {

  const organizationIdParam = organizationId ? `?organization_id=${organizationId}` : '';

  const url = `/pathway/${id}${organizationIdParam}`;
  const { data } = await requestFactory(HTTP_METHOD.GET, url);

  return ConstructPathway(data.data);
};

// Create pathway
export const createPathway = async (data: NewPathway): Promise<any> => {

  const body = {
    name: data.name,
    credits_to_complete: data.credits,
    active: data.active,
    certificate_title: data.certificateTitle,
    certificate_text: data.certificateText,
    certificate_link: data.certificateLink,
    certificate_active: data.certificateActive,
    pathway_cluster_id: data.careerCluster.id,
    pathway_type_id: data.type.id,

    courses: [
      ...data.middleSchoolCourses,
      ...data.supportingCourses,
      ...data.explorerCourses,
      ...data.concentratorCourses,
      ...data.completerCourses,
      ...data.postSecondaryResources,
    ].map((c: PathwayCourse) => ({
      name: c.name,
      link: c.link,
      course_type: c.type,
      credits: c.credits,
      concurrent_enrollment: (c.concurrentEnrollment as BooleanListMenuItem).value,
      recommended_explorer_course: (c.recommendedExplorerCourse as BooleanListMenuItem).value,
      active: c.active,
    })),
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/pathway', undefined, body);

  return response;
};

// Update pathway
export const updatePathway = async (data: { id: number, body: NewPathway, deletedCourses: number[] }): Promise<any> => {

  const body = {
    name: data.body.name,
    credits_to_complete: data.body.credits,
    active: data.body.active,
    certificate_title: data.body.certificateTitle,
    certificate_text: data.body.certificateText,
    certificate_link: data.body.certificateLink,
    certificate_active: data.body.certificateActive,
    pathway_cluster_id: data.body.careerCluster.id,
    pathway_type_id: data.body.type.id,

    courses: [
      ...data.body.middleSchoolCourses,
      ...data.body.supportingCourses,
      ...data.body.explorerCourses,
      ...data.body.concentratorCourses,
      ...data.body.completerCourses,
      ...data.body.postSecondaryResources,
    ].map((c: PathwayCourse) => ({
      id: c.courseId,
      name: c.name,
      link: c.link,
      course_type: c.type,
      credits: c.credits,
      concurrent_enrollment: (c.concurrentEnrollment as BooleanListMenuItem).value,
      recommended_explorer_course: (c.recommendedExplorerCourse as BooleanListMenuItem).value,
      active: c.active,
    })),

    deleted_courses: data.deletedCourses,
  };

  const response = await requestFactory(HTTP_METHOD.PATCH, `/pathway/${data.id}`, undefined, body);

  return response;
};

export const schoolAdminUpdatePathway = async (data: { id: number, body: NewPathway, organizationId?: number }): Promise<any> => {

  const organizationParam = data.organizationId ? `?organization_id=${data.organizationId}` : '';
  const url = `/pathway/school/${data.id}${organizationParam}`;

  const body = {
    is_hidden: !data.body.active,
    certificate_is_hidden: !data.body.certificateActive,
    note: data.body.note,

    courses: [
      ...data.body.middleSchoolCourses,
      ...data.body.supportingCourses,
      ...data.body.explorerCourses,
      ...data.body.concentratorCourses,
      ...data.body.completerCourses,
      ...data.body.postSecondaryResources,
    ].map((c: PathwayCourse) => ({
      id: c.courseId,
      alternate_course_name: c.alternateCourseName,
      concurrent_enrollment: (c.concurrentEnrollment as BooleanListMenuItem).value,
      is_hidden: !c.active,
    })),
  };

  const response = await requestFactory(HTTP_METHOD.PATCH, url, undefined, body);

  return response;
};

// Delete pathway
export const deletePathway = async (id: number): Promise<any> => {

  const response = await requestFactory(HTTP_METHOD.DELETE, `/pathway/${id}`);

  return response;
};

export const togglePathwayActive = async (id: number): Promise<void> => {
  await requestFactory(HTTP_METHOD.POST, `/pathway/activestate/${id}`);
};

export const togglePathwayCertificateActive = async (id: number): Promise<void> => {
  await requestFactory(HTTP_METHOD.POST, `/pathway/certificateactive/${id}`);
};

export const togglePathwayActiveForSchool = async (id: number, isHidden: boolean, organizationId?: number): Promise<boolean> => {
  const body = {
    pathway_id: id,
    is_hidden: isHidden,
    organization_id: organizationId,
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/pathway/hidden-from-school', undefined, body);

  return response.data.data;
};

export const togglePathwayCertificateActiveForSchool = async (id: number, isHidden: boolean, organizationId?: number): Promise<boolean> => {
  const body = {
    pathway_id: id,
    certificate_hidden: isHidden,
    organization_id: organizationId,
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/pathway/certificate-hidden-from-school', undefined, body);

  return response.data.data;
};

export const togglePathwayCourseActive = async (id: number): Promise<void> => {
  await requestFactory(HTTP_METHOD.POST, `/pathwaycourse/activestate/${id}`);
};

export const togglePathwayCourseActiveForSchool = async (id: number, isHidden: boolean, organizationId?: number): Promise<boolean> => {
  const body = {
    pathway_course_id: id,
    is_hidden: isHidden,
    organization_id: organizationId,
  };

  const response = await requestFactory(HTTP_METHOD.POST, '/pathwaycourse/hidden-from-school', undefined, body);

  return response.data.data;
};

export const dismissPathwayUpdatedBanner = async (pathwaySchoolId: number): Promise<void> => {
  const body = {
    pathway_school_id: pathwaySchoolId,
  };

  await requestFactory(HTTP_METHOD.POST, '/pathway/dismiss-update-banner', undefined, body);
};
