import React, {
  useEffect, useState
} from 'react';
import './OpportunityDetails.scss';
import {
  useLocation,
  useNavigate, useParams
} from 'react-router-dom';
import { Button } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ResourceType } from '../../../features/resources/resources.model';
import {
  useAppDispatch, useAppSelector
} from '../../../app/hooks';
import { userSelectors } from '../../../features/user/user.slice';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { AppRoutes } from '../../../app/app.types';
import {
  getResourceById, approveResource, rejectResource
} from '../../../features/resources/resources.thunks';
import { ListMenuItem } from '../../../components/forms/form.types';
import { resourceSelectors } from '../../../features/resources/resources.slice';
import { startCase } from 'lodash';
import ApproveRejectCard from '../../../components/details/ApproveRejectCard';
import { setAlert } from '../../../features/alert/alert.slice';
import { resourceTagSelectors } from '../../../features/resourceTags/resourceTags.slice';
import { getResourceTagList } from '../../../features/resourceTags/resourceTags.thunks';
import { User } from '../../../features/user/user.model';
import { adminGetUserById } from '../../../features/user/user.api';
import dayjs from 'dayjs';

interface OpportunityDetailsProps { }

const OpportunityDetails: React.FC<OpportunityDetailsProps> = () => {

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();

  const user = useAppSelector(userSelectors.selectUser);
  const resourceDetails = useAppSelector(resourceSelectors.selectResourceDetails);
  const tags = useAppSelector(resourceTagSelectors.selectResourceTagList);

  const [
    updatedByUser,
    setUpdatedByUser,
  ] = useState<User | null>(null);

  const [
    resourceType,
    setResourceType,
  ] = useState(ResourceType.internship);

  useEffect(() => {
    if (!params || !params.resourceId) return;
    const id = parseInt(params.resourceId);

    (state.type && state.type == ResourceType.internship) ?
      setResourceType(ResourceType.internship) :
      setResourceType(ResourceType.opportunity);

    dispatch(getResourceById({
      id: id,
      type: resourceType,
    }));

    dispatch(getResourceTagList());
  }, []);

  useEffect(() => {
    if (resourceDetails.updatedAt && resourceDetails.updatedBy && updatedByUser === null) {
      adminGetUserById(resourceDetails.updatedBy).then((res: User) => {
        setUpdatedByUser(res);
      });
    }
  }, [
    resourceDetails,
  ]);

  const [
    viewAllInstitution,
    setViewAllInstitution,
  ] = useState(false);

  const onApproveResource = () => {
    dispatch(approveResource({
      id: resourceDetails.id,
      type: resourceType,
    }))
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: `Successfully approved the ${resourceType}.`,
        }));
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: `Unable to approve the ${resourceType}.`,
        }));
      });
  };

  const onRejectResource = () => {
    dispatch(rejectResource({
      id: resourceDetails.id,
      type: resourceType,
    }))
      .then(() => {
        dispatch(setAlert({
          type: 'success',
          message: `Successfully rejected the ${resourceType}.`,
        }));

        navigate(-1);
      })
      .catch(() => {
        dispatch(setAlert({
          type: 'error',
          message: `Unable to reject the ${resourceType}.`,
        }));
      });
  };

  return (
    <div className="card-background opportunity-details">
      <div className="details-header flex_row_jbetween_acenter">
        <div className="back_arrow flex_row" onClick={() => navigate(-1)}>
          <ChevronLeftIcon className="carousel-icon" />
          <h3> {startCase(resourceType)} Details</h3>
        </div>

        <div className="flex_row_jend_acenter">
          {(user.isAdmin && !resourceDetails.approved) && (
            <ApproveRejectCard
              onApprove={onApproveResource}
              onReject={onRejectResource}
            />
          )}

          {(user.isAdmin && resourceDetails.updatedAt && updatedByUser) && (
            <h3 className="updated-banner"><span className="updated-text">Updated:&nbsp; </span> <span className="updated-by-text">{dayjs(resourceDetails.updatedAt).format('MM/DD/YYYY')} by {updatedByUser?.fullname}</span></h3>
          )}

          {(user.isAdmin || user.organizationId === resourceDetails.organizationId) && (
            <Button variant="outlined"
              onClick={() => navigate(AppRoutes.editResource.path.replace(':resourceId', resourceDetails.id.toString()), {
                state: {
                  id: resourceDetails.id,
                  type: resourceType,
                },
              })}
              disabled={!user.isAdmin && !resourceDetails.approved}
            >
              Edit
            </Button>
          )}
        </div>
      </div>
      <div className="main-content flex_column">
        <div className="flex_row_jbetween_acenter">
          <h2> {resourceDetails.title} </h2>

          <div
            className="view-all flex_row_jcenter_acenter"
            onClick={() => navigate(AppRoutes.resourceApplicants.path.replace(':resourceId', resourceDetails.id.toString()), {
              state: {
                title: resourceDetails.title,
                type: resourceType,
              },
            })}
          >
            <div> {resourceDetails.numberApplied} Applications </div>
            <p className="flex_row_acenter">
              View All <ChevronRightIcon />
            </p>
          </div>
        </div>

        <span>
          <p>DESCRIPTION</p>
          <p>{resourceDetails.description || '-'}</p>
        </span>

        <div className="other-details flex_row_jbetween">
          <div className="left-column flex_col">
            <span>
              <p>INSTITUTION / ORGANIZATION</p>
              <p>{resourceDetails.organizationName || '-'}</p>
            </span>

            <span>
              <p>APPLICATION LINK</p>
              <p>{resourceDetails.url || '-'}</p>
            </span>

            <span>
              <p>START DATE</p>
              <p>{resourceDetails.startDate || '-'}</p>
            </span>

            <span>
              <p>END DATE</p>
              <p>{resourceDetails.endDate || '-'}</p>
            </span>

            <span>
              <p>ZIP CODE</p>
              <p>{resourceDetails.zipcode || '-'}</p>
            </span>

            <span>
              <p>ORGANIZATION TYPE</p>
              {resourceDetails.userTypes && !!resourceDetails.userTypes.length ?
                <p>{resourceDetails.userTypes.map((e: ListMenuItem) => e.label).join(', ')}</p> :
                <p> - </p>
              }
            </span>

            <span>
              <p>GRADUATION YEARS</p>
              {resourceDetails.graduationYears && !!resourceDetails.graduationYears.length ?
                <p>{resourceDetails.graduationYears.map((e: number) => e).join(', ')}</p> :
                <p> - </p>
              }
            </span>
          </div>

          <div className="right-column flex_col">

            <span>
              <p>INSTITUTIONS</p>
              <p>
                {resourceDetails.institutions && (
                  <>
                    {/* if has more than 20 institutions show 20 and View-All/View-Less button */}
                    {resourceDetails.institutions && resourceDetails.institutions.length <= 20 ? (
                      resourceDetails.institutions.map((e: ListMenuItem) => e.label).join(', ')
                    ) : (viewAllInstitution) ? (
                      resourceDetails.institutions.map((e: ListMenuItem) => e.label).join(', ')
                    ) : (
                      resourceDetails.institutions.slice(0, 19).map((e: ListMenuItem) => e.label).join(', ')
                    )}
                    {/* Show View All/View Less button */}
                    {(resourceDetails.institutions.length > 20) && (
                      <div className="flex_jcenter">
                        <Button className="center-view-all-button" variant="text"
                          onClick={() => setViewAllInstitution(!viewAllInstitution)}>
                          {viewAllInstitution ? 'View Less' : 'View All'}
                        </Button>s
                      </div>)
                    }
                  </>
                )}
              </p>
            </span>

            <span>
              <p>CAREERS</p>
              {
                resourceDetails.careers && !!resourceDetails.careers.length ?
                  <p>{resourceDetails.careers.map((e: ListMenuItem) => e.label).join(', ')}</p> :
                  <p> - </p>
              }
            </span>

            <span>
              <p>TAGS</p>
              {(resourceDetails.tags && !!resourceDetails.tags.length && !!tags.length) ? (
                <p>
                  {resourceDetails.tags && resourceDetails.tags.map(a => a.name).join(', ')}
                </p>
              ) : (
                <p> - </p>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;
